<template>
  <div class="page">
    <el-row :gutter="20">
      <el-col :span="12">
        <h2>产品埋词</h2>
        <el-input type="textarea" :rows="5" v-model="setWords.keyword" placeholder="请输入关键词（一行一个关键词）"></el-input>
        <el-form :inline="true" style="margin-top: 10px">
          <el-form-item label="外标签">
            <el-input v-model="setWords.html_wrap_tag" placeholder="请输入外标签"></el-input>
          </el-form-item>
          <el-form-item label="内标签">
            <el-input v-model="setWords.html_tag" placeholder="请输入内标签"></el-input>
          </el-form-item>
        </el-form>

        <h2>选择产品</h2>
        <el-table :data="setWords.productList" border>
          <el-table-column label="商品ID" prop="goods_id" width="80"></el-table-column>
          <el-table-column label="商品信息">
            <template slot-scope="scope">
              <el-image style="width: 70px; height: 70px;" :src="scope.row.imgUrl || scope.row.gallery"></el-image>
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="setWords.productList.splice(scope.$index, 1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button style="margin-top: 10px" type="primary" @click="showPop = true">选择商品</el-button>
        <el-button style="margin-top: 10px" type="primary" @click="save">保存</el-button>
        <el-button style="margin-top: 10px"@click="clear">重置</el-button>
      </el-col>
    </el-row>

    <PopView :visible.sync="showPop" title="商品选择" chooseGoods @confirm="showPopSubmit"></PopView>
  </div>
</template>

<script>
import PopView from "@/views/components/popView";
import {apiGoodsSetWord} from "@/request/api";
export default {
  name: "setWords",
  components: {
    PopView
  },
  data() {
    return {
      showPop: false,
      setWords: {
        keyword: "",
        productList: [],
        html_wrap_tag: "div",
        html_tag: "span"
      },

    };
  },
  methods:{
    save(){
      var setWordsObj= {
        words: this.setWords.keyword,
        ids: [],
        html_wrap_tag: this.setWords.html_wrap_tag,
        html_tag: this.setWords.html_tag
      }
      // setWordsObj.words = this.setWords.keyword.split("\n");
      setWordsObj.ids = this.setWords.productList.map(item => item.goods_id);
      console.log(setWordsObj)
      apiGoodsSetWord(setWordsObj).then(res => {
        if(res.code == 200) {
          this.$message.success("保存成功");
        }
      })

    },
    clear() {
      this.setWords.keyword = "";
      this.setWords.productList = [];
      this.setWords.html_wrap_tag = "div";
      this.setWords.html_tag = "span";
    },

    showPopSubmit(data){
      var newList = []
      data.forEach(dataItem => {
       var bool = this.setWords.productList.some(productItem => {
          return productItem.goods_id == dataItem.goods_id;
        });
        console.log(bool)
        if(!bool) {
          newList.push(dataItem);
        }
      });
      this.setWords.productList = [...this.setWords.productList,...newList];
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.text-danger {
  display: inline-block;
  color: #f00;
  font-size: .24rem;
  margin-top: 5px;
}
.text-danger::before {
  content: "*";
  color: #f00;
  font-size: .24rem;
  margin-right: 5px;
}
</style>